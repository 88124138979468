import React, { useContext } from "react";
import style from "./Navbar.module.css";
import { getPassword, isAuthenticated, logout } from "../States/Actions/Auth";
import { useNavigate } from "react-router-dom";
import { FaCog } from "react-icons/fa";
import SocketContext from "../Utils/SocketContext";


function Navbar() {
  const navigate = useNavigate();

  const tryLogout = () => {
    logout(() => {
      if (!isAuthenticated()) {
        navigate("/");
      }
    });
  };

  const goDashboard = () => {
    navigate("/inicio");
  };
  const goBookings = () => {
    navigate("/reservas");
  };
  const goBoard = () => {
    navigate("/quadro");
  };
  const goRequests = () => {
    navigate("/pedidos");
  };
  const goSimulator = () => {
    navigate("/simulador");
  };
  const goCommissions = () => {
    navigate("/comissoes");
  };
  const goRates = () => {
    navigate("/tarifas");
  };

  const socket = useContext(SocketContext);

  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      subscribeToPushNotifications(); // Proceed to subscribe the user
    } else {
      console.error('Notification permission denied.');
    }
  };

  const subscribeToPushNotifications = async () => {
    try {
      const registration = await navigator.serviceWorker.ready;

      const vapidPublicKey = 'BBNZFwMIvQhT1qPCv6BNd5M6i-ud_K5IpMX01rMGmeNCiQkY_iFuKRa9ilJv0J_-KDGDlo_QkKOz7XlqCsxP9-c';
      const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
      });

      const payload = { "password": getPassword(), "data": subscription }

      // Send subscription to backend
      socket.emit('register_subscription', payload);
    } catch (error) {
      console.error('Failed to subscribe to push notifications:', error);
    }
  };

  // Helper function for VAPID key conversion
  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  return (
    <div className={style.navbar}>
      <img
        alt="logo"
        src="./Logos/logo_transparent.png"
        className={style.logo}
        onClick={tryLogout}
      ></img>
      <div className={style.buttondiv}>
        <button className={style.button} onClick={goDashboard}>
          Início
        </button>
        <button className={style.button} onClick={goBookings}>
          Reservas
        </button>
        <button className={style.button} onClick={goBoard}>
          Quadro
        </button>
        <button className={style.button} onClick={goRequests}>
          Pedidos
        </button>
        <button className={style.button} onClick={goSimulator}>
          Simulador
        </button>
        <button className={style.button} onClick={goRates}>
          Tarifas
        </button>
        <FaCog onClick={requestNotificationPermission}></FaCog>
      </div>
    </div>
  );
}

export default Navbar;
